<template>
  <v-app >
    <Navigation/>
    <v-main class="home notifications">
      <v-container class="px-lg-6 px-sm-0 py-lg-8 py-sm-0">
        <router-view></router-view>
        <div class="content" transition="scroll-y-reverse-transition" :style="{borderTopColor:theme.primaryColor} ">
          <div class="content__header">
            <h1 class="content__header--title float-left" >
              Notificaciones
            </h1>
          </div>

          <hr class="content__divider mb-3"/>

          <div class="service row">
            <v-row>
                <v-list three-line>
                    <template v-for="(item, index) in notifications">
                        <v-list-item
                            :key="index"
                            class="notification"
                            @click.stop="dialog = true">
                            <v-list-item-avatar height="50" width="50">
                                <v-img :src="'https://cdn.vuetifyjs.com/images/lists/4.jpg'"></v-img>
                            </v-list-item-avatar>

                            <v-list-item-content style="height: 70px; margin-top: 7px;">
                                <v-list-item-title class="notification_title" v-html="item.title"></v-list-item-title>
                                <v-list-item-subtitle class="notification_text" v-html="item.description"></v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action class="notification_date">
                                <p class="notification_date--text">23 de septiembre de 2021</p>
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                </v-list>
            </v-row>
            <v-dialog
                v-model="dialog"
                width="600px"
            >
            <v-card class="dialog_notification__detail">
                <v-card-title
                  class="dialog_notification__detail--head mb-4 py-1 pt-3"
                  :style="{backgroundColor: theme.primaryColor}"
                >

                <a
                  href="javascript:;"
                  @click.stop="dialog = false"
                >
                  <CloseIcon/>
                </a>

                </v-card-title>
                <v-card-text>
                    <p class="dialog_notification__detail--title">Título del video título del video título del video título del video con 2 renglones máximo</p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum
                </v-card-text>
                <v-card-text class="dialog_notification__detail--date">
                    23 de septiembre de 2021
                </v-card-text>
            </v-card>
            </v-dialog>
          </div>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
  // @ is an alias to /src
  import Logo from '../assets/img/logo.png'

  export default {
    name: 'Benefits',
    data () {
      return {
        actual_page: 1,
        notifications: [],
        current_notifications: 0,
        data: {
            attributes: {
                id: ''
            }
        },
        Logo: Logo,
        theme:{
          primaryColor: '#1CBF20',
          service: 'notifications'
        },
        hoverTabs: false,
        showCategory: true,
        months: ['Enero', 'Febrero', 'Marzo', 'Abril'],
        dialog: false,
        items: [
        {
          id: 1,
          title: 'Título de la notificación con nombre muy largo a con renglones máximo',
          description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
          date: '7 de julio de 2021',
          category: 'Ciencia y Tecnología',
          img: ''
        },
        {
          id: 2,
          title: 'Título de la notificación con nombre muy largo a con renglones máximo',
          description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
          date: '7 de julio de 2021',
          category: 'Tecnología',
          img: ''
        },
        {
          id: 3,
          title: 'Título de la notificación con nombre muy largo a con renglones máximo',
          description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
          date: '7 de julio de 2021',
          img: ''
        },
        {
          id: 4,
          title: 'Título de la notificación con nombre muy largo a con renglones máximo',
          description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
          date: '7 de julio de 2021',
          img: ''
        }
      ]
      }
    },
    components: {
    },
    mounted(){
      this.send_request()
      this.checkDevice();
    },
    methods:{
      send_request(){
        this.$http.get("users/notifications?page="+this.actual_page, {
          headers:
          this.getUserToken() != '' ?
          {
              "Authorization": "Bearer " + this.getUserToken(),
              "X-Device-ID" : this.buildDeviceId(),
              "Geolocation" : "lat: " + this.getLatitude() + ", long: " + this.getLongitude()
          } :
          {
            "X-Device-ID" : this.buildDeviceId(),
            "Geolocation" : "lat: " + this.getLatitude() + ", long: " + this.getLongitude()
          }
        }).then(function(response){
          console.log(response);
          this.current_notifications = 0
          for(var key in response.body.data){
            this.notifications.push(response.body.data[key]);
            if (response.body.data[key].attributes.state != "SEEN") {
              this.current_notifications += 1
            }
          }
          this.updateNotifications(this.current_notifications)
          this.updateBadgeNotifications(this.current_notifications)
          localStorage.setItem("pending_notifications", this.current_notifications);
          if(response.body.meta != undefined && response.body.meta != null){
            if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
              this.checkToken(response.body.meta.authentication_token)
            }
          }
        }, function(response){
          // this.$router.push({name: 'log_in'})
          console.log(response);
        });
      },
    }
  }
</script>
